<template>
  <div>
    <div class='mb-6'>
      <select
        v-model='selectValue'
        @change='updateInput'
        class='border py-1 px-2 rounded block w-full'>
        <option disabled></option>
        <option v-for='affiliation in selectableAffiliations'
          :key='`affiliation-${affiliation.id}`'
          :value='affiliation'>
          {{selectedAffiliationMainValue(affiliation)}}
        </option>
        <option value='other'>기타</option>
      </select>
      <input
        v-if='notListedAffiliation'
        v-model='customMainInputValue'
        class='border py-1 px-2 rounded block w-full mt-2'
        type='text'>
    </div>
    <div>
      <label class='uppercase text-sm text-gray-500 mb-2'>{{ subAffiliationLabel }}</label>
      <input
        v-model='customSubInputValue'
        class='border py-1 px-2 rounded block w-full mt-2'
        type='text'>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListOfAffiliationsHelpers  from '@/utils/list-of-affiliations-helpers.js'

export default {
  name: 'FormFieldAffiliation',
  props: [
    'value',
    'metaData'
  ],
  data () {
    return {
      selectValue: {},
      customMainInputValue: '',
      customSubInputValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.selectableAffiliations.findIndex(affiliation => affiliation.korean === newVal.hospitalName)
          if (index > -1) {
            this.selectValue = this.selectableAffiliations[index]
            this.customMainInputValue = this.selectedAffiliationMainValue(this.selectValue)
            this.customSubInputValue = this.selectedAffiliationSubValue(this.selectValue)
          } else {
            this.selectValue = 'other'
            if (this.mainAffiliationValue.toLowerCase() === 'organization') {
              this.customMainInputValue = newVal.organization
              this.customSubInputValue = newVal.hospitalName
            } else {
              this.customMainInputValue = newVal.hospitalName
              this.customSubInputValue = newVal.organization
            }
          }
        }
      }, 
      immediate: true,
    },
    affiliationHospitalNameValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:affiliationHospitalNameValue', newVal)
        }
      }, 
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
    ]),
    selectableAffiliations () {
      return ListOfAffiliationsHelpers.listOfAffiliations(this.showingEventId)
    },
    notListedAffiliation () {
      return typeof this.selectValue === 'string' &&  this.selectValue.toLowerCase() === 'other'
    },
    mainAffiliationValue () {
      return this.metaData.mainAffiliationValue ? this.metaData.mainAffiliationValue : 'organization'
    },
    subAffiliationLabel () {
      return this.mainAffiliationValue.toLowerCase() === 'organization' ? 'Affiliation (국문)' : 'Affiliation (English)'
    },
    affiliationHospitalNameValue () {
      if (this.notListedAffiliation) {
        if (this.mainAffiliationValue.toLowerCase() === 'organization') {
          return {
            organization: this.customMainInputValue,
            hospitalName: this.customSubInputValue,
          }
        } else {
          return {
            hospitalName: this.customMainInputValue,
            organization: this.customSubInputValue,
          }
        }
      } else {
        return {
          organization: this.selectValue.english,
          hospitalName: this.selectValue.korean,
        }
      }
    },
  },
  methods: {
    selectedAffiliationMainValue (affiliation) {
      return this.mainAffiliationValue.toLowerCase() === 'affiliation' ? affiliation.english : affiliation.korean
    },
    selectedAffiliationSubValue (affiliation) {
      return this.mainAffiliationValue.toLowerCase() === 'affiliation' ? affiliation.korean : affiliation.english
    },
    updateInput () {
      if (this.notListedAffiliation) {
        this.customMainInputValue = ''
        this.customSubInputValue = ''
      } else {
        this.customMainInputValue = this.selectedAffiliationMainValue(this.selectValue)
        this.customSubInputValue = this.selectedAffiliationSubValue(this.selectValue)
      }
    },
  },
}
</script>
